@use 'theme/common/deviceSize.module.sass' as device
@use './styles/variable.module.sass' as v

.root
  width: 100%
  z-index: v.$z-root
  overflow: hidden

  .navGroup
    position: relative
    z-index: v.$z-navGroup
    max-width: 1920px
    margin: -3% auto 40px auto

  .background
    position: fixed
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    z-index: 0
    width: 100%
    height: 100vh
    background-size: cover
    background-position: bottom
    background-image: url('/views/activity240903/images/bg/BG_mob.jpg')

    +device.desktop
      background-image: url('/views/activity240903/images/bg/BG_web.jpg')
