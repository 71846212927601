@use 'theme/common/deviceSize.module.sass' as device
@use 'theme/common/mixin' as mxn

$z-scope: ( "bg-top-pc": 0, "bg-car-pc": 1, "bg-bottom-pc": 2 )

@keyframes shiny
  0%
    opacity: 1
    transform: rotate(0deg) scale(0)

  50%
    opacity: 1
    transform: rotate(-180deg) scale(1)

  100%
    opacity: 1
    transform: rotate(-360deg) scale(0)

@keyframes carComeDown
  0%
    opacity: 1
    scale: 0.5
    transform: translate(10%, -15%)
  100%
    opacity: 1
    scale: 1
    transform: translate(0%, 0%)

// 標題閃光動畫
@keyframes ScanLights
  0%
    transform: translate(-100%, 50%) rotate(-60deg)
  100%
    transform: translate(100%, 50%) rotate(-60deg)


.root
  padding: 0

  .fullImageMobile
    width: 100%
    position: relative

    +mxn.aspect-ratio(1080, 1000)

    +device.desktop
      display: none

  .fullImageDesktop
    position: relative
    display: none

    +device.desktop
      display: block

    .top
      width: 100%
      position: relative
      z-index: map-get($z-scope, bg-top-pc)

      +mxn.aspect-ratio(1920, 1200)

    .bottom
      width: 100%
      position: absolute
      z-index: map-get($z-scope, bg-bottom-pc)
      top: 0%
      left: 0

      +mxn.aspect-ratio(1920, 1200)

      .logo
        // 大標題
        left: 0
        -webkit-mask-size: contain
        -webkit-mask: url(../../images/banner/logo_word.png) no-repeat center center
        padding-bottom: 15%
        position: absolute
        top: 39.4%
        width: 50%
        z-index: 2
        overflow: hidden
        right: 0
        margin: auto
        display: block
        -webkit-mask-size: contain
        mask-size: contain

        // 閃光
      .logo:before
        content: ''
        position: absolute
        top: 0
        right: 0
        bottom: 0
        z-index: 1
        left: 0
        width: 200%
        height: 100px
        background-image: linear-gradient( to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.65), rgba(255, 255, 255, 0) )
        transform-origin: center center
        transform: translate(-100%, 50%) rotate(-60deg)
        animation: ScanLights 4s linear infinite

    .carContainer
      position: absolute
      width: 100%
      z-index: map-get($z-scope, bg-car-pc)
      top: 25.5%
      left: 65.5%
      transform: translate(-50%, -50%)

      .carAnim
        position: relative
        opacity: 0
        transform: translateX(75%)

        &.isActive
          animation: carComeDown 1.5s 0s 1 ease forwards

      .car
        position: relative
        z-index: 0
        width: 52%
        +mxn.aspect-ratio(1046, 591)

    .ann
        position: absolute
        width: 25.5%
        height: auto
        top: 0.6%
        left: 57.5%
        z-index: map-get($z-scope, bg-car-pc)
        +mxn.aspect-ratio(588, 745)

    .star
      position: absolute
      z-index: 1

      +mxn.aspect-ratio(215, 242)

      img
        animation: shiny 4s linear infinite


    .star1
      width: 5%
      top: 45.5%
      left: 33%

      img
        animation-delay: -0s

    .star2
      width: 7%
      top: 40.5%
      left: 46.5%

      img
        animation-delay: -1.85s

    .star3
      width: 7%
      top: 36%
      left: 71%

      img
        animation-delay: -0.45s


