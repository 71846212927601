@use 'theme/common/deviceSize.module.sass' as device
@use 'theme/common/mixin' as mxn

$z-scope: ( "bg-bottom-pc": 0, "title-pc": 1, "bg-top-pc-l": 2, "bg-car-pc": 3, "bg-top-pc-r": 4, "star": 5 )

@keyframes shiny
  0%
    opacity: 1
    transform: rotate(0deg) scale(0)

  50%
    opacity: 1
    transform: rotate(-180deg) scale(1)

  100%
    opacity: 1
    transform: rotate(-360deg) scale(0)

@keyframes carComeDown
  0%
    opacity: 1
    scale: 0.5
    transform: translate(0%, -5%)
  100%
    opacity: 1
    scale: 1
    transform: translate(0%, 0%)

@keyframes breathingScale
  0%
    transform: scale(1)

  50%
    transform: scale(1.025)

  100%
    transform: scale(1)

.root
  padding: 0

  .fullImageMobile
    width: 100%
    position: relative

    +mxn.aspect-ratio(1080, 1000)

    +device.desktop
      display: none

  .fullImageDesktop
    position: relative
    display: none

    +device.desktop
      display: block

    .topL, .topR
      width: 100%
      position: absolute
      top: 0
      left: 0

      +mxn.aspect-ratio(1920, 1200)

    .topL
      z-index: map-get($z-scope, bg-top-pc-l)

    .topR
      z-index: map-get($z-scope, bg-top-pc-r)

    .bottom
      width: 100%
      position: relative
      z-index: map-get($z-scope, bg-bottom-pc)
      top: 0%
      left: 0

      +mxn.aspect-ratio(1920, 1200)

    .carContainer
      position: absolute
      width: 100%
      z-index: map-get($z-scope, bg-car-pc)
      top: 54.5%
      left: 47%
      transform: translate(-50%, -50%)

      .carAnim
        position: relative
        display: flex
        justify-content: center
        align-items: center
        opacity: 1
        transform-origin: right center

        &.isActive
          animation: carComeDown 1s 0s 1 ease forwards

      .car
        position: relative
        z-index: 0
        width: 68%

        +mxn.aspect-ratio(1308, 675)

    .titleContainer
      position: absolute
      top: 2%
      left: 14.5%
      width: 56.5%
      z-index: map-get($z-scope, title-pc)

    .title
      position: relative
      width: 100%
      animation: breathingScale 4.25s 0s infinite ease-in-out forwards

      +mxn.aspect-ratio(1094, 322)

    .star
      position: absolute
      z-index: map-get($z-scope, star)

      +mxn.aspect-ratio(215, 242)

      img
        animation: shiny 4s linear infinite

    .star1
      width: 5%
      top: 0.5%
      left: 23%

      img
        animation-delay: -0s

    .star2
      width: 7%
      top: -10.5%
      left: 32.5%

      img
        animation-delay: -1.85s