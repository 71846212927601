:global(.relative)
  position: relative

:global(.image_obj)
  position: absolute
  width: 100%
  height: 100%
  top: 0
  left: 0
  bottom: 0
  right: 0

  img
    width: 100%
    height: 100%

