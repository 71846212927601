@use 'theme/common/deviceSize.module.sass' as device
@use 'theme/common/mixin' as mxn

$z-scope: ( "bg-top-pc": 0, "bg-car-pc": 1, "bg-bottom-pc": 2 )

@keyframes shiny
  0%
    opacity: 1
    transform: rotate(0deg) scale(0)

  50%
    opacity: 1
    transform: rotate(-180deg) scale(1)

  100%
    opacity: 1
    transform: rotate(-360deg) scale(0)

@keyframes carComeDown
  0%
    opacity: 1
    transform-origin: 75% 95%
    transform: rotate(-1deg) translateY(-110%)

  1%
    opacity: 1
    transform-origin:  75% 95%
    transform: rotate(-2deg) translateY(-110%)

  20%
    opacity: 1
    transform-origin:  75% 95%
    transform: rotate(-0.5deg) translateY(-0.05%)

  30%
    opacity: 1
    transform-origin:  75% 95%
    transform: rotate(0deg) translateY(-0.5%)

  50%
    opacity: 1
    transform-origin: 0% 0%
    transform: rotate(0deg) translateY(0%)

  100%
    opacity: 1
    transform-origin: 75% 95%
    transform: rotate(0deg) translateY(0%)

.root
  padding: 0

  .fullImageMobile
    width: 100%
    position: relative

    +mxn.aspect-ratio(1080, 1000)

    +device.desktop
      display: none

  .fullImageDesktop
    position: relative
    display: none

    +device.desktop
      display: block

    .top
      width: 100%
      position: relative
      z-index: map-get($z-scope, bg-top-pc)

      +mxn.aspect-ratio(1920, 1200)

    .bottom
      width: 100%
      position: absolute
      z-index: map-get($z-scope, bg-bottom-pc)
      top: 0%
      left: 0

      +mxn.aspect-ratio(1920, 1200)

    .carContainer
      position: absolute
      width: 100%
      z-index: map-get($z-scope, bg-car-pc)
      top: 30%
      left: 46.5%
      transform: translate(-50%, -50%)

      .carAnim
        position: relative
        display: flex
        justify-content: center
        align-items: center
        opacity: 0
        transform-origin: 25% 20%

        &.isActive
          animation: carComeDown 2s 0.35s 1 linear forwards

      .car
        position: relative
        z-index: 0
        width: 63%

        +mxn.aspect-ratio(1181, 566)

    .star
      position: absolute
      z-index: 1

      +mxn.aspect-ratio(215, 242)

      img
        animation: shiny 4s linear infinite


    .star1
      width: 5%
      top: 45.5%
      left: 33%

      img
        animation-delay: -0s

    .star2
      width: 7%
      top: 40.5%
      left: 46.5%

      img
        animation-delay: -1.85s

    .star3
      width: 7%
      top: 36%
      left: 71%

      img
        animation-delay: -0.45s