@use 'theme/common/deviceSize.module.sass' as device
@use 'theme/common/mixin' as mxn

$z-scope: ( "bg-top-pc": 0, "bg-car-pc": 1, "bg-bottom-pc": 2 )

@keyframes shiny
  0%
    opacity: 1
    transform: rotate(0deg) scale(0)

  50%
    opacity: 1
    transform: rotate(-180deg) scale(1)

  100%
    opacity: 1
    transform: rotate(-360deg) scale(0)

@keyframes shiny-2
  0%
    opacity: 0
  10%
    opacity: 1
  20%
    opacity: 1
  30%
    opacity: 0
  40%
    opacity: 0
  50%
    opacity: 0
  60%
    opacity: 1
  70%
    opacity: 0
  80%
    opacity: 1
  90%
    opacity: 1
  100%
    opacity: 0

@keyframes carComeDown
  0%
    opacity: 1
    transform: translateX(75%)
  100%
    opacity: 1
    transform: translateX(0%)

.root
  padding: 0

  .fullImageMobile
    width: 100%
    position: relative

    +mxn.aspect-ratio(1080, 890)

    +device.desktop
      display: none

  .fullImageDesktop
    position: relative
    display: none

    +device.desktop
      display: block

    .top
      width: 100%
      position: relative
      z-index: map-get($z-scope, bg-top-pc)

      +mxn.aspect-ratio(1920, 1200)

    .bottom
      width: 100%
      position: absolute
      z-index: map-get($z-scope, bg-bottom-pc)
      top: 0%
      left: 0

      +mxn.aspect-ratio(1920, 1200)

    .carContainer
      position: absolute
      width: 92%
      z-index: map-get($z-scope, bg-car-pc)
      top: 36%
      left: 65.5%
      transform: translate(-50%, -50%)

      .carAnim
        position: relative
        opacity: 0
        transform: translateX(75%)

        &.isActive
          animation: carComeDown 1.5s 0s 1 ease forwards

        .star
          img
            animation: shiny 4s linear infinite

        .star3
          width: 7%
          top: 13%
          left: 26.5%

        .star4
          width: 7%
          top: 12%
          left: 7.5%

      .car
        position: relative
        z-index: 0
        width: 63%

        +mxn.aspect-ratio(1078, 777)

    .star
      position: absolute
      z-index: 1

      +mxn.aspect-ratio(215, 242)

      img
        animation: shiny-2 3s ease infinite

    .star1
      width: 5%
      top: 51.5%
      left: 29%

    .star2
      width: 7%
      top: 50.5%
      left: 36%

    .star3
      width: 7%
      top: 49%
      left: 67%