@use 'theme/common/deviceSize.module.sass' as device
@use 'theme/common/mixin.sass' as mxn

.root
  position: relative
  background: #110

.wrapper
  width: 90%
  padding: 1rem 0 22.5% 0
  max-width: 950px
  margin: 0 auto
  color: #e8e6e3
  display: flex
  flex-direction: column
  align-items: center

  +device.desktop
    flex-direction: row
    padding: 1rem 0

.content
  margin-bottom: 0.5rem

  +device.desktop
    margin-bottom: 0
    margin-right: 0.5rem

.copyright
  margin: 0
  margin-bottom: 5px

.copyright, .announcement
  font-size: 12px

.rating
  width: 40px
  +mxn.aspect-ratio(172, 172)

  +device.desktop
    width: 60px

