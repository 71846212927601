
@use 'theme/common/deviceSize.module.sass' as device
@use 'theme/common/mixin.sass' as mxn

.root
  position: relative

.errorImg
  width: 100%
  background-size: cover
  background-image: url('/images/common/notFound_404_m_01.jpg')
  +mxn.aspect-ratio(1500, 2668)

  +device.desktop
    +mxn.aspect-ratio(1920, 1080)
    background-image: url('/images/common/notFound_404_pc_01.jpg')