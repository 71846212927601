@use 'theme/common/deviceSize.module.sass' as device

$z-scope: ( "content-box": 0, "title": 10,  )

.root
  position: relative
  max-width: 1920px
  margin: 0 auto 70px auto

  .wrapper
    padding: 0 3%

    +device.desktop
      max-width: 1520px
      margin: 0 auto
      padding: 0 12%
      box-sizing: border-box

  .title
    position: relative
    margin: 0
    z-index: map-get($z-scope, title)
    padding: 0

    +device.desktop
      padding: 0

    img
      width: 100%

  .contentBox
    position: relative
    margin-top: -8%
    z-index: map-get($z-scope, content-box)
