@use 'theme/common/deviceSize.module.sass' as device

.root
  position: relative
  z-index: 1
  width: 100%
  justify-content: center
  display: flex
  color: #fff

  a
    text-decoration: none

  .container
    max-width: 31.25rem
    height: 100vh
    max-height: -webkit-fill-available
    display: flex
    flex-direction: column
    justify-content: center
    align-items: stretch

    +device.desktop
      max-width: none
      min-width: 31.25rem

  .title
    height: 4.375rem
    background: #002147
    padding: 2rem
    border-radius: 3.75rem 3.75rem 0px 0px
    text-align: center
    font-size: 1.125rem
    color: #d9c998
    display: flex
    align-items: center
    justify-content: center

  .content
    position: relative
    width: 100%
    min-height: 12.5rem
    max-width: 34.375rem
    background: #0e3e74
    text-align: center
    padding-top: .625rem
    padding: .9375rem

    +device.desktop
      max-width: none

    .eighteenPlus
      width: 56px
      margin: 0 auto .625rem auto

      img
        width: 100%
        height: 100%

    .contentBottom
      position: absolute
      width: 100%
      height: 1.2rem
      background: transparent linear-gradient(0deg, #01142380 0%, #0114230E 80%, #01142300 100%) 0% 0% no-repeat
      bottom: 0
      left: 0

    .text
      font-size: 1rem
      margin: .3125rem 0 .625rem 0
      padding: 0 .3125rem

      p
        margin: 0

    .btn
      display: flex
      align-items: center
      justify-content: center

      .notFullBtn,.fullBtn
        transition: background 0.3s
        cursor: pointer

      .notFullBtn
        background: #e10000
        color: #fff

        &:hover
          background: darken(#e10000, 10)


      .fullBtn
        background: #00bf47
        margin-left: 1.875rem
        color: #fff

        &:hover
          background: darken(#00bf47, 10)

      button, .notFullBtn
        width: 6.375rem
        height: 2.125rem
        border: .0625rem solid #001B2C
        border-radius: .3125rem
        font-size: .875rem
        display: flex
        align-items: center
        justify-content: center
        font-family: Verdana, Microsoft JhengHei UI

  .footer
    background: transparent linear-gradient(180deg, #0B396E 0%, #011327 100%) 0% 0% no-repeat padding-box
    height: 4.375rem
    display: flex
    align-items: center
    justify-content: center
    border-radius: 0px 0px 3.75rem 3.75rem

    .hint
      display: flex
      justify-content: center
      align-items: center
      font-size: 1rem

      input
        width: .875rem
        height: .875rem
        margin-right: .3125rem
