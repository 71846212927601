@use 'theme/common/function.sass' as func
@use 'theme/common/mixin' as mxn
@use 'theme/common/deviceSize.module.sass' as device
@use 'theme/common/variables.module.sass' as v

$desktopWidth: func.strip-unit(v.$desktopWidth)
$mobileWidth: func.strip-unit(v.$mobileWidth)

.root
  position: relative

  .title
    width: 92.5%
    margin: 1rem auto

    +mxn.aspect-ratio(559, 119)

    +device.desktop
      margin: 3rem auto 1rem auto
      width: 50%

.contentIcon
  position: relative
  width: func.px2vw(260, $mobileWidth)

  +mxn.aspect-ratio(300, 145)

  +device.desktop
    width: func.px2vw-lock(160, $desktopWidth)