.root
  position: relative
  width: 100%
  min-height: 100vh
  margin: 0 auto

  .rate18Alert
    position: fixed
    top: 0
    left: 0
    width: 100%
    opacity: 0
    pointer-events: none

    &.show
      opacity: 1
      pointer-events: auto

  .rate18AlertBG
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    z-index: 0
    background: linear-gradient(1turn,rgba(0,0,0,.5),rgba(0,0,0,.3) 106.06%)
    backdrop-filter: blur(1.875rem)

