@use 'theme/common/deviceSize.module.sass' as device
@use 'theme/common/mixin' as mxn

$z-scope: ( "bg-bottom-pc": 0, "title-pc": 1, "bg-car-pc": 2, "bg-top-pc-l": 3, "bg-top-pc-r": 4, "star": 5 )

@keyframes shiny
  0%
    opacity: 1
    transform: rotate(0deg) scale(0)

  50%
    opacity: 1
    transform: rotate(-180deg) scale(1)

  100%
    opacity: 1
    transform: rotate(-360deg) scale(0)

@keyframes carComeDown
  0%
    opacity: 1
    scale: 0.5
    transform: translate(0%, -5%)
  100%
    opacity: 1
    scale: 1
    transform: translate(0%, 0%)

@keyframes breathingScale
  0%
    transform: scale(1)

  50%
    transform: scale(1.025)

  100%
    transform: scale(1)

@keyframes starBlink
  10%,20%,60%,80%,90%
    opacity: 1
  0%, 30%,40%,50%,100%
    opacity: 0

@keyframes ScanLights
  0%
    transform: translate(-100%, 50%) rotate(-60deg)
  100%
    transform: translate(100%, 50%) rotate(-60deg)

.root
  padding: 0

  .fullImageMobile
    width: 100%
    position: relative

    +mxn.aspect-ratio(1080, 1000)

    +device.desktop
      display: none

  .fullImageDesktop
    position: relative
    display: none

    +device.desktop
      display: block

    .topL
      width: 100%
      position: absolute
      top: 0
      left: 0

      +mxn.aspect-ratio(1920, 1200)

    .rightTopCoin
      width: 30%
      position: absolute
      top: -10%
      right: 0
      z-index: 100

      +mxn.aspect-ratio(1920, 1200)

    .rightBottomCoin
      width: 30%
      position: absolute
      bottom: 15%
      right: -5%
      z-index: 100

      +mxn.aspect-ratio(1920, 1200)

    .topR
      width: 42%
      position: absolute
      bottom: 20%
      left: -10%

      +mxn.aspect-ratio(1920, 1200)

    .topL
      z-index: map-get($z-scope, bg-top-pc-l)

    .topR
      z-index: map-get($z-scope, bg-top-pc-r)

    .carContainer
      position: absolute
      width: 100%
      z-index: map-get($z-scope, bg-car-pc)
      top: 52%
      left: 47%
      transform: translate(-50%, -50%)

      .carAnim
        position: relative
        display: flex
        justify-content: center
        align-items: center
        opacity: 1
        transform-origin: right center

        &.isActive
          animation: carComeDown 1s 0s 1 ease forwards

    .car
      width: 100%
      z-index: map-get($z-scope, bg-bottom-pc)
      background-image: url('/views/activity240903/images/banner/04_車.png')
      background-size: cover
      background-position: center
      aspect-ratio: 1920 / 1200
      image-rendering: pixelated

      .star1, .star2
        position: absolute
        width: 200px
        height: 200px
        z-index: map-get($z-scope, bg-bottom-pc)
        background-image: url('/views/activity240903/images/banner/star.png')
        background-size: cover
        background-position: center
        aspect-ratio: 215 / 242
        image-rendering: pixelated
        animation: starBlink 1s infinite

      .star1
        top: 53%
        left: 50.5%
        transform: translate(-50%, -50%)
        animation-delay: 0s

      .star2
        top: 46.5%
        left: 23%
        transform: translate(-50%, -50%)

    .bottom
      width: 100%
      position: relative
      z-index: map-get($z-scope, bg-bottom-pc)
      top: 0
      left: 0
      background-image: url('/views/activity240903/images/banner/05_底層_web.png')
      background-size: cover
      background-position: center
      aspect-ratio: 1920 / 1200
      image-rendering: pixelated

      +mxn.aspect-ratio(1920, 1200)

    .titleContainer
      position: absolute
      top: -5%
      left: -45%
      width: 180%
      z-index: map-get($z-scope, title-pc)

      .title
        left: 0
        padding-bottom: 15%
        position: absolute
        top: 39.4%
        width: 50%
        overflow: hidden
        right: 0
        margin: auto
        display: block
        background-image: url(../../images/banner/01_KV_web.png)
        background-size: contain
        background-repeat: no-repeat
        background-position: center
        -webkit-mask: url(../../images/banner/01_KV_web.png) no-repeat center center
        -webkit-mask-size: contain
        mask: url(../../images/banner/01_KV_web.png) no-repeat center center
        mask-size: contain
        image-rendering: pixelated

        .scanLight
          content: ''
          position: absolute
          top: 0
          right: 0
          bottom: 0
          z-index: 1
          left: 0
          width: 200%
          height: 100px
          background-image: linear-gradient( to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.65), rgba(255, 255, 255, 0) )
          transform-origin: center center
          transform: translate(-100%, 50%) rotate(-60deg)
          animation: ScanLights 8s linear infinite
