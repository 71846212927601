@use 'theme/common/mixin' as mxn
@use 'theme/common/deviceSize.module.sass' as device

.root
  position: relative

  .title
    width: 92.5%
    margin: 1rem auto

    +mxn.aspect-ratio(559, 119)

    +device.desktop
      margin: 3rem auto 1rem auto
      width: 50%