@use 'theme/common/mixin' as mxn
@use 'theme/common/deviceSize.module.sass' as device

.root
  position: fixed
  bottom: 0
  margin: auto
  top: -80px
  display: none
  right: 30px
  transition: left .5s
  align-items: center
  padding-bottom: 11.5%

  +device.desktop
    display: flex

  .toolBoxBorder
    display: inline-block
    border-radius: 10px
    position: relative
    text-align: center
    width: 150px
    background: transparent linear-gradient(180deg, #F2AA0C 0%, #684801 100%) 0% 0% no-repeat padding-box

    .qrCodeContainer
      box-sizing: border-box
      width: 120px
      margin: 15px auto 5px auto
      padding: 10px
      background: #fff
      border-radius: 10px

      .qrCode
        position: relative

        +mxn.aspect-ratio(1, 1)

    .toolBox
      display: inline-block
      border-radius: 5px
      margin: 0 auto
      position: relative
      width: 93%
      padding-bottom: 10px
      background: #F60111
      border: 1px solid #FF4F48
      color: #FFF
      text-align: center

      .toolIcon
        display: inline-block
        position: absolute
        width: 100%
        top: -28%
        left: 20%
        transform: translate(-50%, 0)
        cursor: pointer

        img
          width: 220px

      .title
        display: inline-block
        margin-top: 35px
        font-size: 20px

      p
        margin: 0
        padding: 3px
        font-size: 20px
        text-align: center

        img
          max-width: 124px

      .hr
        margin: 10px auto
        width: 85%
        background-color: #FFDE9E
        border: 0
        border-top: 1px solid rgba(0,0,0,.1)
