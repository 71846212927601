@use 'theme/common/deviceSize.module.sass' as v

$z-scope: ( "defautImg": 0, "hoverImg": 1, "lockImg": 2 )

.root
  position: relative
  display: inline-block
  width: 100%

  &.isActive
    .defaultImg
      opacity: 0

    .hoverImg
      opacity: 1

  &.notAllowed
    cursor: not-allowed

  @media (pointer: fine)
    &:hover
      .defaultImg
        opacity: 0

      .hoverImg
        opacity: 1

  img
    width: 100%

.defaultImg
  position: relative
  z-index: map-get($z-scope, defaultImg)
  transition: opacity .3s linear
  opacity: 1

.hoverImg
  position: absolute
  opacity: 0
  z-index: map-get($z-scope, hoverImg)
  top: 0
  left: 0
  transition: opacity .3s linear

.lockImg
  position: absolute
  opacity: 0
  z-index: map-get($z-scope, lockImg)
  top: 0
  left: 0

  &.isActive
    opacity: 1