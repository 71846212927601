@use 'theme/common/deviceSize.module.sass' as device
@use 'theme/common/mixin.sass' as mxn

.root
  position: fixed
  width: 100%
  left: 0
  bottom: 0
  background: #000000e0

  +device.desktop
    display: none

.wrapper
  display: flex
  flex-direction: row

.link
  flex: 1
  padding: 2.5%

.linkContent
  box-sizing: border-box
  width: 100%
  +mxn.aspect-ratio(190, 140)