@use 'theme/common/variables.module.sass' as v
= sm
  @media screen and (min-width: 640px)
    @content
= md
  @media screen and (min-width: 768px)
    @content
= lg
  @media screen and (min-width: 1024px)
    @content
= xl
  @media screen and (min-width: 1280px)
    @content
= mobile
  @media screen and (max-width: v.$mobileWidth)
    @content
= desktop
  @media screen and (min-width: v.$mobileWidth+1)
    @content
