@use 'theme/common/function.sass' as func
@use 'theme/common/mixin' as mxn
@use 'theme/common/deviceSize.module.sass' as device
@use 'theme/common/variables.module.sass' as v

$desktopWidth: func.strip-unit(v.$desktopWidth)
$mobileWidth: func.strip-unit(v.$mobileWidth)

.root
  position: relative
  display: flex

  .block
    flex: 1
    display: flex
    flex-direction: column
    background-color: #9B0D17

  .titleContainer
    width: 100%
    border: 1px solid #39D77D
    background-color: #0E3824
    padding: 2% 0

    +device.desktop
      border-width: func.px2vw-lock(2, $desktopWidth)
      padding: 3% 0

    .title
      display: flex
      width: 100%
      margin: 0.35rem auto

      img
        width: 100%
        height: 100%

      +device.desktop
        width: 65%
        margin: 0 auto

  .content
    display: flex
    flex-direction: column
    align-items: center
    border: 1px solid #208847
    border-top: 0px solid transparent
    padding: 0 0 5%
    flex: 1
    background-color: #0D3824

    +device.desktop
      border-width: 2px
      padding: 5%

    .award
      width: 60%
      display: flex
      flex-direction: column
      z-index: 1
      padding: 5% 0 2% 0

      +device.desktop
        padding: 0 0 2% 0

    .contentText
      margin-top: 1%
      position: relative
      z-index: 1

      +device.desktop
        margin-top: 0
        width: 100%

      p
        $mobileWidth: func.strip-unit(v.$mobileWidth)
        font-size: func.px2vw(40, $mobileWidth)

        +device.desktop
          $desktopWidth: func.strip-unit(v.$desktopWidth)
          width: 100%
          font-size: func.px2vw-lock(24, $desktopWidth)
