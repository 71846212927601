@use 'theme/common/function.sass' as func
@use 'theme/common/mixin' as mxn
@use 'theme/common/deviceSize.module.sass' as device
@use 'theme/common/variables.module.sass' as v

$desktopWidth: func.strip-unit(v.$desktopWidth)
$mobileWidth: func.strip-unit(v.$mobileWidth)

.root
  position: relative

  .title
    width: 92.5%
    margin: 1rem auto

    +mxn.aspect-ratio(559, 119)

    +device.desktop
      margin: 1rem auto 1rem auto
      width: 50%

.contentIcon
  position: relative
  width: func.px2vw(260, $mobileWidth)

  +device.desktop
    width: func.px2vw-lock(250, $desktopWidth)

  +device.mobile
    width: func.px2vw-lock(370,$mobileWidth)

.block
  flex: 1 1 33.33%
  max-width: 33.33%
  box-sizing: border-box

  @media (max-width: 600px)
    flex: 1 1 50%
    max-width: 50%
