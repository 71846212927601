@use 'theme/common/function.sass' as func
@use 'theme/common/mixin' as mxn
@use 'theme/common/deviceSize.module.sass' as device
@use 'theme/common/variables.module.sass' as v

$desktopWidth: func.strip-unit(v.$desktopWidth)
$mobileWidth: func.strip-unit(v.$mobileWidth)

.root
  position: relative
  background-color: #9B0D17
  margin-bottom: func.px2vw-lock(3, $mobileWidth)

  +device.desktop
    margin-bottom: func.px2vw-lock(3, $desktopWidth)

  .titleContainer
    width: 100%
    border-style: solid
    border-color: #39D77D
    border-width: func.px2vw(1, $mobileWidth)
    background-color: #0E3824
    padding: func.px2vw(12, $mobileWidth) func.px2vw(6, $mobileWidth)

    +device.desktop
      padding: func.px2vw-lock(14, $desktopWidth)
      border-width: func.px2vw-lock(2, $desktopWidth)

    .title
      display: flex
      width: 100%
      margin: 0 0

      .dateString
        width: 100%
        text-align: center
        font-size: func.px2vw(52, $mobileWidth)

        +device.desktop
          font-size: func.px2vw-lock(37, $desktopWidth)

      +device.desktop
        margin: 1.5% 0

      img
        width: 100%
        height: 100%

      +device.desktop
        width: 100%
        margin: 0 auto
