@use 'theme/common/mixin' as mxn
@use 'theme/common/deviceSize.module.sass' as device

.root
  position: fixed
  width: 100%
  bottom: 13%
  right: 3.5%
  cursor: pointer
  transition: all .5s ease-in-out
  display: flex
  justify-content: flex-end
  pointer-events: none

  +device.desktop
    bottom: 7.5rem

  .scrollTop
    width: 10%
    position: relative
    background: none
    border: none
    padding: 0
    pointer-events: all
    +mxn.aspect-ratio(1, 1)

    +device.desktop
      width: 60px