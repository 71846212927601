html
  box-sizing: border-box
  -webkit-font-smoothing: antialiased

*, *::before, *::after
  box-sizing: inherit

body
  margin: 0
  font-family: Verdana,Microsoft JhengHei UI
  font-weight: 400

#root
  background-size: cover
  background-position: center center
  background-image: url('/images/common/webBG_pc_01.jpg')