@use 'theme/common/function.sass' as func
@use 'theme/common/mixin' as mxn
@use 'theme/common/deviceSize.module.sass' as device
@use 'theme/common/variables.module.sass' as v

$desktopWidth: func.strip-unit(v.$desktopWidth)
$mobileWidth: func.strip-unit(v.$mobileWidth)

.luckyDrawTd
  span
    display: flex
    justify-content: center
    align-items: center
    img
      width: 40px
      margin-right: 0.5rem
  +device.mobile
    span
      display: flex
      flex-direction: column
      align-items: center
      justify-content: center
      font-size: 1rem
      img
        width: 25px
        margin-right: 0.5rem