@use 'theme/common/mixin' as mxn
@use 'theme/common/deviceSize.module.sass' as device

.root
  position: relative

  .title
    width: 100%
    margin: 0 auto 0.25rem auto

    +mxn.aspect-ratio(1127, 311)

    +device.desktop
      +mxn.aspect-ratio(913, 200)
      margin: 0 auto 0.25rem auto
      width: 100%