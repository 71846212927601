@use 'theme/common/function.sass' as func
@use 'theme/common/mixin' as mxn
@use 'theme/common/deviceSize.module.sass' as device
@use 'theme/common/variables.module.sass' as v

$desktopWidth: func.strip-unit(v.$desktopWidth)
$mobileWidth: func.strip-unit(v.$mobileWidth)

.root
  position: relative
  background-color: #cdbfc0
  margin-bottom: 2%
  overflow: hidden
  border-radius: func.px2vw(20, $mobileWidth)

  +device.desktop
    margin-bottom: 3%
    border-radius: func.px2vw-lock(10, $desktopWidth)

  .titleContainer
    width: 100%
    border: 2px solid #39D77D
    background-color: #0E3824
    padding: 1.5% 0
    border-top-left-radius: func.px2vw-lock(20, $mobileWidth)
    border-top-right-radius: func.px2vw-lock(20, $mobileWidth)

    +device.desktop
      padding: 1.5% 0
      border-top-left-radius: func.px2vw-lock(10, $desktopWidth)
      border-top-right-radius: func.px2vw-lock(10, $desktopWidth)

    .title
      display: flex
      width: 100%
      margin: 1.5% 0

      img
        width: 100%
        height: 100%

      +device.desktop
        width: 50%
        margin: 0 auto

  .content
    display: flex
    flex-direction: column
    align-items: center
    border: 2px solid #208847
    border-top: 0px solid transparent
    padding: 0
    background-color: #0D3824
    border-bottom-left-radius: func.px2vw(20, $mobileWidth)
    border-bottom-right-radius: func.px2vw(20, $mobileWidth)

    +device.desktop
      border-bottom-left-radius: func.px2vw-lock(10, $desktopWidth)
      border-bottom-right-radius: func.px2vw-lock(10, $desktopWidth)
      flex-direction: row
      justify-content: space-between
      padding: 5% 5% 15% 7%

    .awardBG
      width: 100%
      position: relative
      z-index: 0
      justify-content: center
      align-items: center

      > img
        width: 100%

      +device.desktop
        position: absolute
        top: 0
        left: 0

    .awardM
      width: 60%
      display: flex
      flex-direction: column
      position: absolute
      left: 50%
      top: 42.5%
      transform: translate(-50%, -50%)
      z-index: 1

      +device.desktop
        display: none

    .awardD
      display: none

      +device.desktop
        position: relative
        display: block
        width: 40%
        z-index: 1

    .contentContainer
      display: flex
      flex-direction: column
      justify-content: center
      align-items: center
      position: absolute
      top: func.px2vw(44, $mobileWidth)

      +device.desktop
        position: relative
        width: 50.5%
        top: 0

    .contentText
      white-space: nowrap
      margin-top: func.px2vw(520, $mobileWidth)
      z-index: 1

      +device.desktop
        position: relative
        margin-top: 0
        width: 50.5%

        +mxn.aspect-ratio(1920, 1121)

      p
        font-size: func.px2vw(63, $mobileWidth)

        +device.desktop
          width: 100%
          position: absolute
          top: 50%
          left: 50%
          transform: translate(-50%, -50%)
          font-size: func.px2vw-lock(36, $desktopWidth)
