@use 'theme/common/deviceSize.module.sass' as device

.activity-content-root
  position: relative
  margin: 0
  text-align: left
  word-wrap: break-word

  .wrapper
    padding: 7% 2.5%
    background: #330000
    border: 1px solid #FEEF9F
    border-radius: 3px
    color: #fff

    +device.desktop
      padding: 100px 7% 70px

  .hidden
    +device.desktop
      display: none

  .m\:hidden
    +device.mobile
      display: none

  .block
    +device.desktop
      display: block

  .m\:block
    +device.mobile
      display: block

  .flex
    +device.desktop
      display: flex

  .m\:flex
    +device.mobile
      display: flex

  .table
    +device.desktop
      display: table

  .m\:table
    +device.mobile
      display: table

  .mb-3
    +device.desktop
      margin-bottom: 0.75rem

  .m\:mb-3
    +device.mobile
      margin-bottom: 0.75rem

  ol
    +device.mobile
      padding-inline-start: 30px

  p, ol
    font-size: 24px
    letter-spacing: 1px

    +device.mobile
      font-size: 16px

  p, ol
    margin: 0

  ol li
    &:not(:last-child)
      padding-bottom: 1rem

  a
    color: #03CBFF
    background: transparent
    transition: color .5s

  img
    max-width: 100%

  table
    width: 100%
    color: #fff
    table-layout: fixed
    border-collapse: separate
    border-spacing: 0
    font-size: 20px

    &.table-bg-1
      background: #30026dd9

    &.table-bg-2
      background: #85070F

    &.table-bg-3
      background: #7B594A

    &.table-bg-4
      background: #66765F

    &.table-bg-5
      background: #85070F

    &.table-bg-6
      background: #2D0B8D

    &.table-bg-7
      background-color: #680400

    +device.mobile
      font-size: 18px

    th, td
      text-align: center
      border: 1px solid #fff

    thead
      th
        padding: 10px 0

    tbody
      td
        padding: 10px 1rem

    // TABLE CUSTOM STYLE:
    $table-border-radius: 8px

    &.table-radius
      border-radius: $table-border-radius

    .table-title-bg-1
      background: transparent linear-gradient(180deg,#814ed3,#6909cb) 0 0 no-repeat padding-box

    .table-title-bg-2
      background-color: #E45C57

    .table-title-bg-3
      background-color: #491600

    .table-title-bg-4
      background-color: #0E3824

    .table-title-bg-5
      background-color: #E45C57

    .table-title-bg-6
      background-color: #3621B6

    .table-title-bg-7
      background-color: #890800

    .table-border-blue
      border-color: #03CBFF

    .table-rounded-t
      border-top-left-radius: $table-border-radius
      border-top-right-radius: $table-border-radius

    .table-rounded-b
      border-bottom-left-radius: $table-border-radius
      border-bottom-right-radius: $table-border-radius

    .table-rounded-tl
      border-top-left-radius: $table-border-radius

    .table-rounded-tr
      border-top-right-radius: $table-border-radius

    .table-rounded-bl
      border-bottom-left-radius: $table-border-radius

    .table-rounded-br
      border-bottom-right-radius: $table-border-radius

    &.table-bg-5
      .giftCard
        width: 50%
