@use 'theme/common/function.sass' as func
@use 'theme/common/variables.module.sass' as v
@use 'theme/common/deviceSize.module.sass' as device

$mobileWidth: func.strip-unit(v.$mobileWidth)
$desktopWidth: func.strip-unit(v.$desktopWidth)

.root
  position: relative

  .wrapper
    +device.desktop
      display: flex
      flex-direction: row

    & > :not(:last-child)
      margin-bottom: func.px2vw(60, $mobileWidth)

      +device.desktop
        margin-bottom: 0
        margin-right: func.px2vw(20, $desktopWidth)


.frame
  border: .0625rem solid #fff
  background-color: #85070F
  align-items: center
  text-align: center
  border-radius: func.px2vw(10, $mobileWidth)
  overflow: hidden

  +device.desktop
    border-width: .125rem
    border-radius: func.px2vw(5, $desktopWidth)
    flex: 1

  & > :not(:last-child)
    border-bottom: .0625rem solid #fff

    +device.desktop
      border-bottom: .125rem solid #fff

  .title
    font-size: func.px2vw(72, $mobileWidth)
    margin: 0
    padding: func.px2vw(20, $mobileWidth) 0
    background-color: #E45C57

    +device.desktop
      font-size: func.px2vw(32, $desktopWidth)
      padding: func.px2vw(10, $desktopWidth)

  .infoWrapper
    padding: func.px2vw(50, $mobileWidth) 0
    display: flex
    flex-direction: column
    justify-content: center

    +device.desktop
      padding: func.px2vw(10, $desktopWidth)
      height: 350px

  .awardImage
    margin: 0 auto
    width: 75%

  .productName
    margin: func.px2vw(20, $mobileWidth) 0
    font-size: func.px2vw(60, $mobileWidth)
    color: #FDF0BE

    +device.desktop
      font-size: func.px2vw(20, $desktopWidth)
      margin: func.px2vw(4, $desktopWidth)

  .productCount
    font-size: func.px2vw(50, $mobileWidth)

    +device.desktop
      font-size: func.px2vw(18, $desktopWidth)

  .otherAwardTitle
    padding: func.px2vw(20, $mobileWidth) 0
    font-size: func.px2vw(50, $mobileWidth)
    background-color: #E45C57

    +device.desktop
      font-size: func.px2vw(20, $desktopWidth)
      padding: func.px2vw(12, $desktopWidth)

  .otherAwardText
    text-align: left
    padding: func.px2vw(20, $mobileWidth)
    font-size: func.px2vw(50, $mobileWidth)

    +device.desktop
      font-size: func.px2vw(18, $desktopWidth)
      padding: func.px2vw(18, $desktopWidth)


