@use 'theme/common/deviceSize.module.sass' as device

.root
  position: relative
  max-width: 1600px
  margin: 0 auto
  padding: 0 3%

  margin-top: 1rem

  +device.desktop
    margin-top: 0
    padding: 0 30px

  .navList
    display: grid
    max-width: 1236px
    margin: 1.5% auto 0
    padding: 0
    grid-template-columns: repeat(2, 1fr)

    +device.desktop
       grid-template-columns: repeat(4, 1fr)

    li
      list-style-type: none
