@use 'theme/common/deviceSize.module.sass' as device
@use 'theme/common/mixin' as mxn

$z-scope: ( "bg-bottom-pc": 0, "title-pc": 1, "bg-car-pc": 2, "bg-top-pc-l": 3, "bg-top-pc-r": 4, "star": 5 )

@keyframes shiny
  0%
    opacity: 1
    transform: rotate(0deg) scale(0)

  50%
    opacity: 1
    transform: rotate(-180deg) scale(1)

  100%
    opacity: 1
    transform: rotate(-360deg) scale(0)

@keyframes carComeDown
  0%
    opacity: 1
    scale: 0.5
    transform: translate(0%, -5%)
  100%
    opacity: 1
    scale: 1
    transform: translate(0%, 0%)

@keyframes breathingScale
  0%
    transform: scale(1)

  50%
    transform: scale(1.025)

  100%
    transform: scale(1)

@keyframes starBlink
  10%,20%,60%,80%,90%
    opacity: 1
  0%, 30%,40%,50%,100%
    opacity: 0

@keyframes ScanLights
  0%
    transform: translate(-100%, 50%) rotate(-60deg)
  100%
    transform: translate(100%, 50%) rotate(-60deg)

@keyframes neonFlicker
  0%
    opacity: 1
  33.47%
    opacity: 0
  33.48%
    opacity: 1
  56.51%
    opacity: 0
  70%
    opacity: 0
  76%
    opacity: 1
  80%
    opacity: 0
  85%
    opacity: 1
  90%
    opacity: 0
  95%
    opacity: 0
  100%
    opacity: 1

@keyframes floatRight
  0%
    transform: translateY(0)
  50%
    transform: translateY(-15px)
  100%
    transform: translateY(0)

@keyframes floatLeft
  0%
    transform: translateY(0)
  50%
    transform: translateY(15px)
  100%
    transform: translateY(0)

.root
  padding: 0

  .fullImageMobile
    width: 100%
    position: relative

    +mxn.aspect-ratio(1080, 1000)

    +device.desktop
      display: none

    .mobileBackground
      position: absolute
      width: 100%
      height: 100%
      top: 0
      left: 0
      bottom: 0
      right: 0

      img
        width: 100%

  .fullImageDesktop
    position: relative
    display: none

    +device.desktop
      display: block

    .topL
      width: 100%
      position: absolute
      top: 0
      left: 0
      animation: floatLeft 3s ease-in-out infinite

      +mxn.aspect-ratio(1920, 1200)

    .rightCard
      width: 100%
      position: absolute
      right: 0
      z-index: 100
      animation: floatRight 3s ease-in-out infinite

      +mxn.aspect-ratio(1920, 1200)

    .rightBottomCoin
      width: 100%
      position: absolute
      bottom: 0%
      right: 0%
      z-index: 100

      +mxn.aspect-ratio(1920, 1200)

    .topR
      width: 100%
      position: absolute
      bottom: 0%
      left: 0%

      +mxn.aspect-ratio(1920, 1200)

    .topL
      z-index: map-get($z-scope, bg-top-pc-l)

    .topR
      z-index: map-get($z-scope, bg-top-pc-r)

    .carContainer
      position: absolute
      width: 100%
      z-index: map-get($z-scope, bg-car-pc)
      top: 50%
      left: 50%
      transform: translate(-50%, -50%)

      .carAnim
        position: relative
        display: flex
        justify-content: center
        align-items: center
        opacity: 1
        transform-origin: right center

        &.isActive
          animation: carComeDown 1s 0s 1 ease forwards

    .car
      width: 100%
      z-index: map-get($z-scope, bg-bottom-pc)
      background-image: url('/views/activity250113/images/banner/重機.png')
      background-size: cover
      background-position: center
      +mxn.aspect-ratio(1308, 675)

      .star1
        position: absolute
        width: 100px
        height: 100px
        z-index: map-get($z-scope, bg-bottom-pc)
        background-image: url('/views/activity250113/images/banner/light.png')
        background-size: cover
        background-position: center
        aspect-ratio: 215 / 242
        image-rendering: pixelated
        animation: starBlink 1s infinite

      .star1
        top: 36%
        left: 25%
        transform: translate(-50%, -50%) rotate(22deg)
        animation-delay: 0s

    .bottom
      width: 100%
      position: relative
      z-index: map-get($z-scope, bg-bottom-pc)
      top: 0
      left: 0
      background-image: url('/views/activity250113/images/banner/07_底圖.png')
      background-size: cover
      background-position: center
      aspect-ratio: 1920 / 1200
      image-rendering: pixelated

      +mxn.aspect-ratio(1920, 1200)

    .titleContainer
      position: absolute
      top: -7.5%
      left: -45%
      width: 180%
      z-index: map-get($z-scope, title-pc)

      .title
        left: 0
        padding-bottom: 15%
        position: absolute
        width: 35%
        right: 0
        margin: auto
        display: block
        position: relative

        .lightOn, .lightOff
          position: absolute
          top: 0
          left: 0
          width: 100%
          height: 100%
          background-size: contain
          background-repeat: no-repeat
          background-position: center

        .lightOn
          background-image: url(/views/activity250113/images/banner/01_開燈kv.png)

        .lightOff
          background-image: url(/views/activity250113/images/banner/01_關燈kv.png)
          animation: neonFlicker 2.5s infinite
